import React, { useState } from 'react'
import s from 'styled-components'

import { Modal, ModalContent, ModalHeader } from './index'
import Loading from '../loading'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const Body = s.div`
  width: 100%;
  padding: 20px;
  text-align: justify;
`

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

export const ModalClose = ({
  id,
  onSubmit,
  onClose,
  textDownload = 'Accept',
  textClose = 'Close',
}) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textDownload}
    </Button.Button5>
    <Button.Button2 onClick={handleClose(id, onClose)}>
      {textClose}
    </Button.Button2>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    setIsLoading(true)

    props.onSubmit(() => {
      setIsLoading(false)
      onReset()
    })
  }

  const onReset = () => {}

  return {
    isLoading,
    onSubmit,
    onReset,
  }
}

function EventDetailAcceptance(props) {
  const { isLoading, onSubmit, onReset } = handle(props)

  return (
    <Modal id="modalEventDetailAcceptance">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalEventDetailAcceptance"
          title={props.locale.ACCEPT_THIS_EVENT}
          onClose={onReset}
        />

        <Body>
          <p>
            Do you want to accept the invitation to <b>{props.event_name}</b>?
          </p>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
        </Body>

        <ModalClose
          id="modalEventDetailAcceptance"
          onSubmit={onSubmit}
          onClose={onReset}
          textDownload={props.locale.ACCEPT}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default EventDetailAcceptance

EventDetailAcceptance.defaultProps = {
  onSubmit: null,
}
