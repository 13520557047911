import React, { useState } from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import s from 'styled-components'

import Button from '../form/buttons'
import NoPreview from '../../images/img_not_available.png'
import Carousel from '../carousel'
import ImageFullScreen from '../image-fullscreen/image-fullscreen'
import JoinEventComponent from '../join-event'
import ReplyMessage from '../reply-message'
import ReplyMessageList from '../reply-message/list'
import { BoxDetail, BoxDetailGallery } from '../box'
import { CarouselGallery } from '../gallery'

const defaultOptions = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
}

const GGMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      defaultOptions={defaultOptions}
      disableDefaultUI
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: props.lat, lng: props.lng }}
          icon={props.icon}
        />
      )}
    </GoogleMap>
  ))
)

function goLing(link) {
  window.location = link
}

let maxReplyMessage = 10

function handleImageFullScreen(props) {
  const [indexImage, setIndexImage] = useState(0)
  const [replyIndex, setReplyIndex] = useState(1)
  const [isShowImage, setonShowImage] = useState(false)

  function onClickImageToFullScreen(e) {
    setIndexImage(parseInt(e.currentTarget.getAttribute('data-index')))
    setonShowImage(true)
  }

  function onCloseImageFullScreen() {
    setonShowImage(false)
  }

  function onLoadMoreReplyMessage() {
    const offset = replyIndex + maxReplyMessage
    setReplyIndex(offset)
    props.onLoadRepilesEvent({
      eventID: props.eventID,
      limit: maxReplyMessage,
      offset,
    })
  }

  return {
    indexImage,
    isShowImage,
    onClickImageToFullScreen,
    onCloseImageFullScreen,
    onLoadMoreReplyMessage,
  }
}

const LayoutPlace = s.div`
  padding: 20px 8px;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  
  grid-template-columns: 60% 40%;
  grid-column-gap: 20px;
  
  & > div{
    padding: 10px;
  }
  
  @media (max-width: 750px) {
    padding: 0;
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0;
  }
`

const BoxWebsite = s.div`
  position: absolute;
  top: 0;
  right: 0;
  
  & > :nth-child(1) {
    margin-right : 10px;
  }
  
  @media (max-width: 750px) {
    margin-top: 20px;
    position: relative;
  }`

const styleImageCover = { width: '100%' }
const style1 = { width: 35 }
const style2 = { paddingBottom: 0 }
const style3 = { paddingLeft: 0 }
const style4 = { padding: '20px 0 20px 0' }
const style5 = { width: 60, height: 60 }
const style6 = { height: '100%' }
const style7 = { height: '400px' }
const style8 = { paddingLeft: 10 }
const style9 = { paddingTop: 20 }
const style10 = { width: '100%' }
const style11 = { paddingTop: 20, color: '#776D6D' }
const style12 = { marginTop: 20, marginBottom: 20, padding: '0px 20px' }
const style13 = { textAlign: 'center' }
const style14 = { backgroundColor: '#E8E8E8', padding: 20 }
const styleFont1 = { fontFamily: `'Noto Serif', serif` }

const handleClick = (fn, ...arg) => () => fn(...arg)

export default props => {
  const {
    indexImage,
    isShowImage,
    onClickImageToFullScreen,
    onCloseImageFullScreen,
    onLoadMoreReplyMessage,
  } = handleImageFullScreen(props)

  let descriptions = props.data.text.split(/\r\n|\r|\n/)
  let descriptionRow = descriptions.length > 4 ? descriptions.length : 4

  descriptions.map(data => {
    descriptionRow += Math.floor(data.length / (window.innerWidth * 0.08))
  })

  return (
    <div className="event-detail-section">
      <div className="cover-images-header">
        <img
          className="cover-img"
          id="imageCoverMain"
          style={styleImageCover}
          src={
            props.data.cover_img || props.data.cover_img.length > 0
              ? props.data.cover_img
              : NoPreview
          }
          alt=""
        />
        <img className="cover-logo-img" src={props.data.logo_img} alt="" />
      </div>
      <BoxDetail>
        <div className="event-detail-body-content">
          <div>
            <h3>{props.data.title}</h3>
            <small>{props.data.time}</small>
            <textarea
              disabled={true}
              style={{
                display: 'block',
                width: '100%',
                resize: 'none',
                height: 'auto',
                outline: 'none',
              }}
              rows={descriptionRow}
              value={props.data.text}
            />
            <BoxWebsite>
              {props.dataOriginal.website ||
              props.dataOriginal.website.length > 0 ? (
                <Button.Button3
                  icon="fas fa-globe-asia"
                  onClick={handleClick(goLing, props.dataOriginal.website)}
                />
              ) : null}
              {props.dataOriginal.facebook_link ||
              props.dataOriginal.facebook_link.length > 0 ? (
                <Button.Button3
                  icon="fab fa-facebook-f"
                  style={style1}
                  onClick={handleClick(
                    goLing,
                    props.dataOriginal.facebook_link
                  )}
                />
              ) : null}
            </BoxWebsite>
          </div>

          <JoinEventComponent
            isOwner={props.isOwner}
            isExpire={props.isExpire}
            auth={props.auth}
            eventID={props.eventID}
            isPayment={props.isPayment}
            privacy={props.dataOriginal.privacy}
            onShare={props.onShare}
            onJoin={props.onJoinEvent}
            onAddCalendar={props.onAddCalendar}
            onViewYourPass={props.onViewYourPass}
            locale={props.locale}
            signinHandler={props.signinHandler}
            clubRegister={props.clubRegister}
          />
          {['checked-in', 'joined'].indexOf(props.isPayment) > -1 && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <ReplyMessage
                eventID={props.eventID}
                profileData={props.dataOriginal.profileData}
                invitationDetail={props.dataOriginal.invitationDetail}
                onSubmit={props.onSubmitReplyMessage}
                locale={props.locale}
              />
            </div>
          )}
        </div>
      </BoxDetail>

      <BoxDetailGallery>
        <div className="event-detail-body-gallery" style={style2}>
          <div className="card__header">
            <h3 style={styleFont1}>{props.locale.PHOTO_GALLERY}</h3>
            <div className="underline bred" />
          </div>
          <div className="photo-gallery__box" style={style8}>
            {props.data.photo_gallery_img.length > 0 && (
              <Carousel showEveryTime>
                <CarouselGallery
                  data={props.data.photo_gallery_img}
                  onClickImageToFullScreen={onClickImageToFullScreen}
                />
              </Carousel>
            )}
            <ImageFullScreen
              data={props.data.photo_gallery_img}
              index={indexImage}
              isShow={isShowImage}
              onClose={onCloseImageFullScreen}
            />
          </div>
        </div>
      </BoxDetailGallery>
      <BoxDetail>
        <LayoutPlace>
          <div>
            <div className="card__header" style={style3}>
              <h3 style={styleFont1}>{props.locale.MAP}</h3>
              <div className="underline bred" />
            </div>
            <div style={style4} id="box-google-map">
              <GGMap
                lat={parseFloat(props.data.lat)}
                lng={parseFloat(props.data.lng)}
                isMarkerShown
                googleMapURL={process.env.GATSBY_GOOGLE_MAP_URL}
                loadingElement={<div style={style6} />}
                containerElement={<div style={style7} />}
                mapElement={<div style={style6} />}
                icon={{ url: props.data.logo_img, scaledSize: style5 }}
              />
            </div>
          </div>
          <div>
            <div className="card__header" style={style3}>
              <h3 style={styleFont1}>{props.locale.PLACE}</h3>
              <div className="underline bred" />
            </div>
            <div style={style9}>
              <img
                src={
                  props.data.place.img || props.data.place.img.length > 0
                    ? props.data.place.img
                    : NoPreview
                }
                alt=""
                className="place-shadow"
                style={style10}
              />
            </div>
            <div style={style11}>
              <p>{props.data.place.content}</p>
            </div>
          </div>
        </LayoutPlace>
      </BoxDetail>
      <BoxDetail>
        {props.isOwner && (
          <div style={style12}>
            <ReplyMessageList
              data={props.dataOriginal.repliesList}
              locale={props.locale}
            />
            <div style={style13}>
              <Button.Button1 onClick={onLoadMoreReplyMessage}>
                {props.locale.LOAD_MORE}
              </Button.Button1>
            </div>
          </div>
        )}
      </BoxDetail>
      <div style={style14}>
        <JoinEventComponent
          isOwner={props.isOwner}
          isExpire={props.isExpire}
          auth={props.auth}
          eventID={props.eventID}
          isPayment={props.isPayment}
          privacy={props.dataOriginal.privacy}
          onShare={props.onShare}
          onJoin={props.onJoinEvent}
          onAddCalendar={props.onAddCalendar}
          onViewYourPass={props.onViewYourPass}
          locale={props.locale}
          signinHandler={props.signinHandler}
          clubRegister={props.clubRegister}
        />
      </div>
    </div>
  )
}
