import React, { useState } from 'react'
import s from 'styled-components'
import swal from 'sweetalert'
import Loading from '../loading'
import { Modal, ModalContent, ModalHeader } from './index'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const Body = s.div`text-align:center;padding: 10px;`
const Table = s.table`
  width:100%;
  & > tbody > tr > td {
    padding: 10px;
    text-align: left;
  }
 `

const TdInput = s.td`
  // color:#C14C40;
  & > input , & > textarea , & > select{
    border: 1px solid #887979;
    border-radius: 6px;
    padding: 10px 10px;
    width: 100%;
  }
  , & > select{
    height: 45px;
    background: #ffffff;
  }
`

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }

export const ModalClose = ({ onSubmit, textSave = 'Save' }) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textSave}
    </Button.Button5>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState(props.profileData.name)
  const [message, setMessage] = useState('')

  const onChangeName = e => setName(e.currentTarget.value)
  const onMessage = e => setMessage(e.currentTarget.value)

  const onSubmit = () => {
    if (name.length <= 0 || message === '') {
      swal(
        props.locale.ERROR,
        props.locale.WARNING_INVALID_INPUT + '!',
        'error'
      )
    } else {
      setIsLoading(true)
      const payload = {
        message,
        id: props.data.id || 0,
        eventID: props.eventID,
      }
      props.onSubmit(payload, () => {
        setIsLoading(false)
        ModalHandler.closeModal('modalReplyMessage')
        setMessage('')
      })
    }
  }
  const onReset = () => {
    setName('')
    setMessage('')
  }

  return {
    isLoading,
    name,
    message,
    onChangeName,
    onMessage,
    onSubmit,
    onReset,
  }
}

function ModalReplyMessage(props) {
  const {
    isLoading,
    name,
    message,
    onChangeName,
    onMessage,
    onSubmit,
    onReset,
  } = handle(props)

  return (
    <Modal id="modalReplyMessage">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalReplyMessage"
          title={props.locale.REPLY_MESSAGE}
          onClose={onReset}
        />
        <Body>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
          <Table>
            <tbody>
              <tr>
                <TdInput>
                  {props.locale.SIGNATURE}:
                  <input type="text" value={name} onChange={onChangeName} />
                </TdInput>
              </tr>
              <tr>
                <TdInput>
                  {props.locale.MESSAGE}:
                  <textarea value={message} onChange={onMessage} rows="4" />
                </TdInput>
              </tr>
            </tbody>
          </Table>
        </Body>
        <ModalClose
          id="modalReplyMessage"
          onSubmit={onSubmit}
          onClose={onReset}
          textSave={props.locale.SEND}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default ModalReplyMessage

ModalReplyMessage.defaultProps = {
  onSubmit: null,
}
