import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import queryString from 'query-string'
import swal from 'sweetalert'
import { getProfileFromId, getProfile } from '../../utils/api'
import moment from 'moment'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import View from '../../components/presentation/event-detail'
import ShareModal from '../../components/popup/share'
import AddCalendarModal from '../../components/popup/add-calendar'
import ModalViewYourPass from '../../components/popup/event-detail-your-pass'
import ModalConfirmJoinFree from '../../components/popup/event-detail-confirm-join-free'
import ModalEventDetailAcceptance from '../../components/popup/event-detail-acceptance'
import Cookies from 'universal-cookie'
import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'
import ModalHandler from '../../utils/modal'
import clubRegisterActions from '../../state/club-register/actions'

const cookies = new Cookies()

class EventDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      invitation_id: null,
      invitation_pin: null,
      selectBanner: 'Default',
    }
    this.signinHandler = this.signinHandler.bind(this)
    this.defaultBanner = this.defaultBanner.bind(this)
  }
  // signinHandeler Use to select Club Banner for login //
  signinHandler (require_login, no_permission, club_auth, invitree_auth, selectBanner) {
    this.setState({selectBanner: selectBanner}, () => {
    if (invitree_auth == null) {
      //window.alert(require_login)
      ModalHandler.openModal('modalSignIn')
      return
    }
    if (club_auth == false) {
      //window.alert(no_permission)
      ModalHandler.openModal('modalJoinClub')
      return
    }
    })
  }

  // defaultBanner Use for set invitree Banner for login
  defaultBanner () {
    this.setState({selectBanner: 'Default'}, () => ModalHandler.openModal('modalSignIn'))
  }
  
  componentDidMount() {
    const { id, invitation_id, invitation_pin } = queryString.parse(
      this.props.location.search
    )
    this.props.checkAuthorization(null, null, null, {
      onSuccess: value => {
        if (value != null) {
          if (
            typeof invitation_id !== 'undefined' &&
            typeof invitation_pin !== 'undefined'
          ) {
            this.props.onLoad(id, 'viewDetail', invitation_id, invitation_pin)
            this.setState({
              id: id,
              invitation_id: invitation_id,
              invitation_pin: invitation_pin,
            })
          } else {
            this.props.onLoad(id, 'viewDetail')
          }
        }
      },
      onError: value => {
        if (
          typeof invitation_id !== 'undefined' &&
          typeof invitation_pin !== 'undefined'
        ) {
          let locale = this.props.app.locale[this.props.app.lang]
          let text = `id=${id}&invitation_id=${invitation_id}&invitation_pin=${invitation_pin}`

          swal(locale.ERROR, locale.ERROR_NOT_LOGIN, 'error').then(() =>
            navigate(`/?${text}`)
          )
        } else {
          this.props.onLoad(id, 'viewDetail')
        }
      },
    })
    this.handleCheckProfile()
  }

  handleCheckProfile = async () => {
    const resData = await getProfile()
      if (resData.data && resData.data.username) {
        const userData = await getProfileFromId(resData.data.username)
        if (userData && userData.data && userData.data._id) {
          cookies.set('_id', userData.data._id, {
            path: '/',
            expires: new Date(
              moment()
                .add(1, 'day')
                .format()
            ),
          })
          this.props.onSetProfile({
            ...userData.data,
          })
        } //else {
          //navigate(`/KMITLEngineerAlumni`)  *** Wait for solution to detect club event
        //}
      }
  }

  componentWillUnmount() {
    this.props.onReset()
  }

  onPopupCalendar = () => ModalHandler.openModal('modalAddCalendar')
  onAddCalendar = id => platform => this.props.onAddCalendar(id, platform)
  onViewYourPass = id => () => {
    const invitation_id =
      this.props.state.eventDetailOriginal.invitationDetail.id || 0
    this.props.onViewYourPass(id, invitation_id)
  }

  onPopupDelayAcceptInvitation = () => {
    let { privacy } = this.props.state.eventDetailOriginal
    let locale = this.props.app.locale[this.props.app.lang]

    // if (privacy === 'private') {
    //   swal(locale.ERROR, locale.JOIN_INVITATION_FAIL, 'error').then(() =>
    //     navigate('/home')
    //   )
    // } else {
    //   setTimeout(
    //     () => ModalHandler.openModal('modalEventDetailAcceptance'),
    //     2000
    //   )
    // }

    setTimeout(() => ModalHandler.openModal('modalEventDetailAcceptance'), 2000)
  }
  onSubmitAcceptInvitation = callback => {
    const { invitation_id, invitation_pin } = this.state
    let { privacy } = this.props.state.eventDetailOriginal
    let locale = this.props.app.locale[this.props.app.lang]

    // if (privacy === 'public_view') {
    //   swal(locale.ERROR, locale.JOIN_INVITATION_FAIL, 'error').then(() => callback())
    // } else {
    //   this.props.onAcceptInvitation(invitation_id, invitation_pin, () => {
    //     ModalHandler.closeModal('modalEventDetailAcceptance')
    //     callback()
    //   })
    // }

    this.props.onAcceptInvitation(invitation_id, invitation_pin, () => {
      ModalHandler.closeModal('modalEventDetailAcceptance')
      callback()
    })
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }

    if (this.props.state.eventDetail.id === 0) {
      return <Loading />
    }

    if (this.state.invitation_id != null && this.state.invitation_pin != null) {
      this.onPopupDelayAcceptInvitation()
    }

    const params = queryString.parse(this.props.location.search)
    return (
      <Layout auth={this.props.auth.currentUser !== null}
              clubRegister={this.props.clubRegister}
              EventType={this.props.state.eventDetailOriginal.privacy}
              selectBanner={this.state.selectBanner}
              defaultBanner={this.defaultBanner}>
        <SEO
          keywords={[`invitree`]}
          og={{
            title: this.props.state.eventDetail.title,
            image: this.props.state.eventDetail.cover_img,
            description: this.props.state.eventDetail.text,
          }}
        />
        <View
          isOwner={
            this.props.state.eventDetailOriginal.profileData.username ===
            this.props.state.eventDetailOriginal.username
          }
          auth={this.props.auth.currentUser !== null}
          clubRegister={this.props.clubRegister}
          eventID={params.id}
          isPayment={this.props.state.eventDetail.isPayment}
          dataOriginal={this.props.state.eventDetailOriginal}
          data={this.props.state.eventDetail}
          onShare={() => this.props.onShare(params.id)}
          onAddCalendar={this.onPopupCalendar}
          onJoinEvent={() =>
            this.props.onChooseEvent(
              this.props.state.eventDetailOriginal.package_id,
              'buy_ticket',
              params.id
            )
          }
          onViewYourPass={this.onViewYourPass(params.id)}
          onSubmitReplyMessage={this.props.onSubmitReplyMessage}
          onLoadRepilesEvent={this.props.onLoadRepilesEvent}
          locale={this.props.app.locale[this.props.app.lang]}
          isExpire={this.props.state.isExpire}
          signinHandler={this.signinHandler}
        />
        <ShareModal id={this.props.state.selectedShareID} />
        <AddCalendarModal
          locale={this.props.app.locale[this.props.app.lang]}
          onSelected={this.onAddCalendar(params.id)}
        />
        <ModalViewYourPass
          invitationId={
            this.props.state.eventDetailOriginal.invitationDetail.id || 0
          }
          isPayment={this.props.state.eventDetail.isPayment}
          data={this.props.state.eventDetailOriginal}
        />
        <ModalConfirmJoinFree
          data={this.props.state.eventDetailOriginal}
          onJoinEvent={() =>
            this.props.onChooseEventForFree(
              this.props.state.eventDetailOriginal.package_id,
              'buy_ticket',
              params.id
            )
          }
          locale={this.props.app.locale[this.props.app.lang]}
        />
        <ModalEventDetailAcceptance
          event_name={this.props.state.eventDetail.title}
          onSubmit={this.onSubmitAcceptInvitation}
          lang={this.props.app.lang}
          locale={this.props.app.locale[this.props.app.lang]}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
    clubRegister: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadDetail,
      onShare: homeActions.onShare,
      onAddCalendar: homeActions.onAddCalendar,
      onJoinEvent: homeActions.onJoinEvent,
      onViewYourPass: homeActions.onViewYourPass,
      onReset: homeActions.onReset,
      onChooseEvent: homeActions.onChooseEvent,
      onChooseEventForFree: homeActions.onChooseEventForFree,
      onSubmitReplyMessage: homeActions.onSubmitReplyMessage,
      onLoadRepilesEvent: homeActions.onLoadRepilesEvent,
      onAcceptInvitation: homeActions.onAcceptInvitation,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailPage)
