import React from 'react'
import s from 'styled-components'
import qrcode from 'qrcode-generator'

import { getLanguage } from '../../locale'
import { Modal, ModalClose, ModalContent, ModalHeaderNoTitle } from './index'
import { getShowDate } from '../../utils/string'

const Body = s.div`text-align:center;padding: 10px;`
const LogoImage = s.img`width: 70px;height: 70px;border-radius: 50px;-webkit-box-shadow: 0 2px 4px;box-shadow: 0 2px 4px;cursor:pointer;`
const BodyTitle = s.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
  
  & > div:nth-child(2) { text-align: left;}
  
  & > div:nth-child(2) > h3 {
    color: #3F3131;
    font-weight: unset;
  }
  
  & > div:nth-child(2) > small {
    color: #776D6D;
    margin-top: 5px;
    margin-bottom: 15px;
    display: block;
  }
`

class ModalViewYourPass extends React.Component {
  componentDidMount() {
    if (this.props.isPayment !== 'checked-in') {
      this.fnGenQR()
    } else {
      this.fnGenTextCheckedin()
    }
  }

  qrRef = ref => (this.qrImage = ref)

  fnGenTextCheckedin = () => {
    this.qrImage.innerHTML = '<h1 style="color:#C14C40">Check In Already</h1>'
  }

  fnGenQR = () => {
    const typeNumber = 4
    const errorCorrectionLevel = 'L'
    const qr = qrcode(typeNumber, errorCorrectionLevel)
    qr.addData(
      `${process.env.GATSBY_URL_REDIRECT}/check-in/?id=${
        this.props.invitationId
      }`
    )
    qr.make()
    this.qrImage.innerHTML = qr.createImgTag(5)
  }

  render() {
    const lang = getLanguage().lang

    return (
      <Modal id="modalViewYourPass">
        <ModalContent maxWidth={400}>
          <ModalHeaderNoTitle id="modalViewYourPass" />
          <Body>
            <BodyTitle>
              <div>
                <LogoImage
                  src={
                    this.props.data.logo ||
                    process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO
                  }
                  alt=""
                />
              </div>
              <div>
                <h3>
                  {lang === 'TH'
                    ? this.props.data.title_th
                    : this.props.data.title_en}
                </h3>
                <small>{getShowDate(lang, this.props.data.date)}</small>
              </div>
            </BodyTitle>
            <img
              src={this.props.data.banner + '?sz=l'}
              alt=""
              style={{ width: '100%', marginTop: 20 }}
            />
            <div style={{ padding: 10 }}>
              <div ref={this.qrRef} />
            </div>
          </Body>
          <ModalClose id="modalViewYourPass" />
        </ModalContent>
      </Modal>
    )
  }
}

export default ModalViewYourPass
