import React from 'react'
import styled from 'styled-components'

import { Modal, ModalContent, ModalHeader } from './index'
import LogoApple from '../../images/icon-apple.png'
import LogoGoogle from '../../images/icon-google.png'

const Icon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`
const Icon2 = styled(Icon)`
  margin-left: 3px;
`
const Body = styled.div`
  text-align: center;
  padding: 10px;
`

const Google = props => (
  <Icon
    src={LogoGoogle}
    alt=""
    style={{ padding: 7 }}
    onClick={props.onClick}
  />
)
const Apple = props => (
  <Icon2
    src={LogoApple}
    alt=""
    style={{ padding: 6 }}
    onClick={props.onClick}
  />
)

function AddCalendarModal(props) {
  return (
    <Modal id="modalAddCalendar">
      <ModalContent maxWidth={300}>
        <ModalHeader
          id="modalAddCalendar"
          title={props.locale.ADD_TO_CALENDAR}
        />
        <Body>
          <Google onClick={() => props.onSelected('GOOGLE')} />
          <Apple onClick={() => props.onSelected('APPLE')} />
        </Body>
      </ModalContent>
    </Modal>
  )
}

export default AddCalendarModal
