import React from 'react'
import s from 'styled-components'

import ModalReplyMessage from '../popup/reply-message'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const BoxContent = s.div`min-height:100px;max-width:700px;padding:20px;margin: auto;text-align:center;color:#B2B2B2;`
const Content = s.p`font-size:30px;margin-top:20px;text-align:right;font-style: italic;`
const Sign = s.p`font-size:17px;margin-top:20px;text-align:right;font-style: italic;`
const styleFont1 = { fontFamily: `'Noto Serif', serif` }
const styleReplyDetail = {
  fontFamily: `'Noto Serif', serif`,
  fontStyle: 'italic',
  textAlign: 'left',
  marginTop: '25px',
}

const onOpenReplyMessage = () => ModalHandler.openModal('modalReplyMessage')

const ReplyMessage = props => {
  return (
    <div className="event-detail-body-gallery" style={{ paddingBottom: 0 }}>
      <div className="card__header" style={{ paddingLeft: 0 }}>
        <h3 style={styleFont1}>{props.locale.REPLY_MESSAGE}</h3>
        <div className="underline bred" />
        <div className="__box-edit_gallery" style={{ top: 0 }}>
          <Button.Button6
            onClick={onOpenReplyMessage}
            style={{ marginRight: 10 }}
          >
            {props.locale.SEND}
          </Button.Button6>
        </div>
      </div>

      <div style={styleReplyDetail}>
        {props.locale.REPLY_MESSAGE_DESCRIPTION}
      </div>

      <BoxContent>
        {props.invitationDetail.reply_message ? (
          <React.Fragment>
            <Content>“{props.invitationDetail.reply_message}”</Content>
            {props.profileData.name && <Sign>- {props.profileData.name}.</Sign>}
          </React.Fragment>
        ) : null}
      </BoxContent>
      <ModalReplyMessage
        profileData={props.profileData}
        eventID={props.eventID}
        data={props.invitationDetail}
        locale={props.locale}
        onSubmit={props.onSubmit}
      />
    </div>
  )
}

export default ReplyMessage
