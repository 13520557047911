import React from 'react'
import Cookies from 'js-cookie'
import s from 'styled-components'
import swal from 'sweetalert'

import Style from './style.module.css'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const BoxButtons = s.div`
    flex-grow: 1;
    text-align: center;
`

const onJoinEvent = (isOwner, auth, eventID, text, fn, privacy, locale, signinHandler, clubRegister) => {
  if (isOwner) {
    return () => {
      return false
    }
  }
  if (!auth) {
    return () => {
      Cookies.set('TEMP_EVENT_ID', eventID)
      const selectBanner ={
        KMITL: 'KMITL',
        Default: 'Default',
      }
      
      if (privacy == 'club_join') {
        signinHandler(`${locale.CLUB_PRMISSION_ALERT}`, `${locale.CLUB_NO_PRMISSION}`, clubRegister.joined, auth.currentUser, selectBanner.KMITL )
      } else {
        signinHandler(`${locale.CLUB_PRMISSION_ALERT}`, `${locale.CLUB_NO_PRMISSION}`, clubRegister.joined, auth.currentUser, selectBanner.Default )
        }
    }
  } else {
    if (text === 'NOT_ALLOW' && privacy !== 'public_join' && (privacy !== 'club_join' || !clubRegister.joined)) {
      return () => {
        swal(locale.ERROR, locale.WARNING_CANT_THIS_EVENT, 'error')
      }
    } else {
      return fn
    }
  }
}

export default props => (
  <React.Fragment>
    <div className={Style.pc}>
      <div className={Style.warper}>
        <BoxButtons>
          <Button.Button2
              onClick={props.onShare}
              style={{ paddingLeft: 77, paddingRight: 30 }}
              icon="fa fa-share-alt"
            >
              {props.locale.SHARE}
            </Button.Button2>
          {['checked-in', 'joined'].indexOf(props.isPayment) > -1 ? (
            <Button.Button1 onClick={props.onViewYourPass}>
              {props.locale.VIEW_YOUR_PASS}
            </Button.Button1>
          ) : (
              <Button.Button1
                disabled={props.isOwner || props.isExpire}
                onClick={onJoinEvent(
                  props.isOwner || props.isExpire,
                  props.auth,
                  props.eventID,
                  props.isPayment,
                  props.onJoin,
                  props.privacy,
                  props.locale,
                  props.signinHandler,
                  props.clubRegister,
                )}
              >
                {props.locale.JOIN_THIS_EVENT}
              </Button.Button1>
            )}
          <Button.Button2
            onClick={props.onAddCalendar}
            style={{ width: 175 }}
            icon="fa fa-calendar-alt"
          >
            {props.locale.ADD_TO_CALENDAR}
          </Button.Button2>
        </BoxButtons>
      </div>
    </div>
    <div className={Style.mobile} style={{ paddingTop: 20 }}>
      <div style={{ textAlign: 'center' }}>
        {['checked-in', 'joined'].indexOf(props.isPayment) > -1 ? (
          <Button.Button1 onClick={props.onViewYourPass}>
            {props.locale.VIEW_YOUR_PASS}
          </Button.Button1>
        ) : (
            <Button.Button1
              disabled={props.isOwner || props.isExpire}
              onClick={onJoinEvent(
                props.isOwner || props.isExpire,
                props.auth,
                props.eventID,
                props.isPayment,
                props.onJoin,
                props.privacy,
                props.locale,
                props.signinHandler,
                props.clubRegister,
              )}
            >
              {props.locale.JOIN_THIS_EVENT}
            </Button.Button1>
          )}
      </div>
      <div className={Style.warper} style={{ padding: '30px 10px 20px' }}>
        <div style={{ flexGrow: 1, textAlign: 'center' }}>
          <Button.Button2
            onClick={props.onShare}
            icon="fa fa-share-alt"
            style={{ padding: 0 }}
          >
            {props.locale.SHARE}
          </Button.Button2>
        </div>
        <div style={{ flexGrow: 1, textAlign: 'center' }}>
          <Button.Button2
            onClick={props.onAddCalendar}
            icon="fa fa-calendar-alt"
            style={{ padding: 0 }}
          >
            {props.locale.ADD_TO_CALENDAR}
          </Button.Button2>
        </div>
      </div>
    </div>
  </React.Fragment>
)
