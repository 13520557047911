import React from 'react'
import s from 'styled-components'

const Wrapper = s.div`margin: 0px auto;position: relative;`
const BoxContent = s.div`min-height:100px;max-width:700px;padding:20px;margin: auto;text-align:center;color:#B2B2B2;height: 210px;overflow-y: scroll;`
const Content = s.p`font-size:30px;margin-top:20px;text-align:right;font-style: italic;`
const Sign = s.p`font-size:17px;margin-top:20px;text-align:right;font-style: italic;`
const styleFont1 = { fontFamily: `'Noto Serif', serif` }

const ReplyMessage = props => {
  if (!props.data) {
    return null
  }
  if (props.data.length <= 0) {
    return null
  }
  return (
    <Wrapper>
      <div className="event-detail-body-gallery" style={{ paddingBottom: 0 }}>
        <div className="card__header" style={{ paddingLeft: 0 }}>
          <h3 style={styleFont1}>{props.locale.REPLY_MESSAGE}</h3>
          <div className="underline bred" />
        </div>
        <BoxContent>
          {props.data.map((v, i) => (
            <React.Fragment key={`reply_message-item-${i}`}>
              <Content>“{v.reply_message}”</Content>
              {v.receiver_name && <Sign>- {v.receiver_name}</Sign>}
            </React.Fragment>
          ))}
        </BoxContent>
      </div>
    </Wrapper>
  )
}

export default ReplyMessage
